import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function CvtOil() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail cvt">
          <SingleProduct
            ProductImage="/images/CVT-1L.webp"
            ProductCategory="Diesel Engine"
            ProductName="<span> CVT Fluid  </span> Full Synthetic"
            ProductDatail="Full synthetic fluid designed for most CVT (Continuously Variable Transmission) gearboxes with steel push-belts or chains. It offers superior anti-wear protection and stable friction characteristics."
            ProductSize1="1L"

            // PRODUCT INFORMATION

            productInfo1= "Our CVT Fluid is based on carefully selected very high-quality base oils, this fluid is specifically designed for CVT transmissions and guarantees smooth and easy shifting, in combination with significant fuel economy. It offers outstanding anti-wear protection and stable friction characteristics. "

            // HOW TO USE

            textuse1= "Check the owner's manual for the correct amount of CVT oil."
            textuse2= "Ensure that the vehicle is on level ground before adding CVT oil."
            textuse3="Remove the dipstick or fill port cap and use a funnel to pour the CVT oil slowly into the dipstick or fill port until it reaches the recommended level on the dipstick."
            textuse4="Start the engine and allow it to run for a few minutes to circulate the CVT oil."
            textuse5="Turn off the engine and wait a few minutes for the CVT oil to settle."
            textuse6="Check the level of the CVT oil using the dipstick or fill port, and add more if necessary to bring it to the recommended level."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

            
            // RELATED PRODUCTS

            SliderProductCategory1="Gear Oils"
            SliderProductCategory2="Gear Oils"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1="/images/empty.png"
            SliderProductImage2="/images/GEAR OIL-1L.webp"
            SliderProductImage3="/images/10W40-5L.webp"
            SliderProductImage4="/images/15W40-5L..webp"

            SliderProductName1="<span> SAE 140  </span>"
            SliderProductName2="<span> SAE 90  </span>"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi1=""
            SliderProductApi2=""
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="SAE 140 gear oil is a premium quality lubricant designed for... "
            SliderProductDatail2="This oil reduces wear, enhances fuel efficiency, and lowers CO2..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/sae140"
            SliderRouteLink2="/sae90"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />        
    </div>
  )
}
