import React, { useState } from 'react';
import "../SearchComp/SearchComp.css"

const SearchFilter = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [matchingProducts, setMatchingProducts] = useState([]);

  const handleSearch = () => {
    const matchingProduct = products.find(
      (product) => product.name.toLowerCase() === searchQuery.toLowerCase()
    );
    if (matchingProduct) {
      // Open corresponding page based on matchingProduct.page
      window.open(matchingProduct.page, '_self');
    }
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchQuery(searchTerm);

    if (searchTerm === '') {
      setMatchingProducts([]); // Hide suggestions when search input is empty
    } else {
      const matchingProducts = products.filter((product) =>
        product.name.toLowerCase().includes(searchTerm)
      );
      setMatchingProducts(matchingProducts);
    }
  };

  const handleItemClick = (productName) => {
    setSearchQuery(productName);
  };

  const products = [
    { name: '0W-20', page: '/0w20' },
    { name: '5W-20', page: '/5w20' },
    { name: '10W-40', page: '/10w40' },
    { name: '15W-40', page: '/15w40' },

    { name: '20W-50', page: '/20w50' },
    { name: 'HD-50', page: '/hd50' },
    { name: 'Engine Flush', page: '/enginflush' },
    { name: 'cvt Fluid', page: '/cvtoil' },

    { name: 'Dexron 3', page: '/dexron' },
    { name: 'Sae90', page: '/sae90' }
  ];

  return (
    <div className='searchDiv'>
      <div className='searchSec'>
      <input
        type="text"
        value={searchQuery}
        onChange={handleInputChange}
        placeholder="Search Products"
      />
      <i onClick={handleSearch}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/></svg>
      </i>
      {/* Display matching product suggestions */}
      {matchingProducts.length > 0 && (
        <div className="suggestionContainer">
          
          {matchingProducts.map((product) => (
            <div
              key={product.name}
              onClick={() => handleItemClick(product.name)}
            >
              {product.name}
            </div>
          ))}
        </div>
      )}
      </div>
      
    </div>
  );
};

export default SearchFilter;
