import React from 'react'
import Image from '../Image/Image'
import Text from '../Text/Text'
import "../EachProduct/EachProduct.css"
import MyButton from '../Button/Button'
import { Link } from 'react-router-dom'


export default function EachProduct({ProductCategory, ProductName, ProductApi, ProductDatail,Src, RouteLink} ) {
  return (
    <div className='EachProduct'>
        <div className="slideritems d-flex">
            <div className="sliderimage">
              <Image Src={Src} Width="90%" />
            </div>
            <div className="productdetail">
            <Text text={ProductCategory} Color="#A9A9A9" FontSize="2rem" />
              <Text  text={ProductName}   Color="black" FontSize="2.6rem" LineHeight="1" Margin="8px 0px 8px 0px" />
              <Text text={ProductApi}   Color="black" FontSize="2rem"/>
              <Text text={ProductDatail} Color="black" Margin="10px 0px 0px 0px" FontSize="1.8rem"/>
              <div className="Gobutton text-end">
                <Link to={RouteLink}>
                  <i>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
                  </i>
                </Link>
              </div>
            </div>
          </div>
    </div>
  )
}
