import React from "react";
import "../Navbar/Navbar.css";
import Image from "../Image/Image";
import NavbarButtons from "../NavbarButtons/NavbarButtons";
import MyButton from "../Button/Button";
import ProductList from "../SearchComp/SearchComp";

export default function Navbar() {
  return (
    <>
      <div className="NavbarMAin">
        <MyButton
          RouteLink="/"
          Src={"/images/sitelogo.png"}
          Color="black"
          ImgWidth="120px"
        />
        <div className="NavbarItemsSec">
          <div className="items">
            <NavbarButtons RouterLink="/aboutus" text="about us" />
          </div>
          <div className="items">
            <NavbarButtons RouterLink="/product" text="products" />
          </div>
          <div className="items">
            <NavbarButtons RouterLink="/contactus" text="contact us" />
          </div>
        </div>
        <div className="SearchIconSec">
          {/* <Image Src="/images/searchIcon.webp" /> */}
          <ProductList />
        </div>
      </div>
      <div class="Mycontainer">
        <input type="checkbox" name="" id="" class="check" />
        <ul class="menu-items">
          <li>
            {" "}
            <NavbarButtons RouterLink="/aboutus" text="about us" />
          </li>
          <li>
            <NavbarButtons RouterLink="/product" text="products" />
          </li>
          <li>
            <NavbarButtons RouterLink="/contactus" text="contact us" />
          </li>
          <li>
          {/* <Image Src="/images/searchIcon.webp" /> */}
          <ProductList />
          </li>
        </ul>
        <div class="ham-menu">
          <span class="line line1"></span>
          <span class="line line2"></span>
          <span class="line line3"></span>
        </div>
      </div>
    </>
  );
}
