import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil10W30() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="/images/empty.png"
            ProductImage2="/images/empty.png"
            ProductCategory="Gasoline/Diesel Engine"
            ProductName="<span> 10W-30 </span> GOLDEN POWER"
            ProductApi="API SL/CF"
            ProductDatail="Introducing a premium semi-synthetic lubricant formulated with meticulously chosen highly refined base oils. This exceptional lubricant, with its ideal viscosity index, is suitable for year-round use, ensuring consistent performance and protection"
            ProductSize1="4L"
            ProductSize2="5L"

            // PRODUCT INFORMATION

            productInfo1= "This oil is perfectly suited for a wide range of gasoline and diesel engines, including both naturally aspirated and supercharged variants. It offers exceptional fluidity at low temperatures and maintains a stable viscosity even under high temperature conditions"

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PUREOIL Car Engine Oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs"
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist"
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

            
            // RELATED PRODUCTS

            SliderProductCategory1="Diesel Engine"
            SliderProductCategory2="Gasoline/Diesel Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1="/images/HD50-5L.webp"
            SliderProductImage2="/images/20W50-5L.webp"
            SliderProductImage3="/images/10W40-5L.webp"
            SliderProductImage4="/images/15W40-5L..webp"

            SliderProductName1="<span> HD 50 </span> Fleet X"
            SliderProductName2="<span> 20W50 </span> Golden power Heavy duty"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi1="API CF"
            SliderProductApi2="API SL/CF"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="HD 50 is performance heavy-duty engine oil, primarily... "
            SliderProductDatail2="A very high-quality mineral engine oil for most 4-stroke..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/hd50"
            SliderRouteLink2="/20w50"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />        
    </div>
  )
}
