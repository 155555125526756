import React, { useState } from 'react'
import "../../Styles/ProductPage.css"
import Navbar from '../../Components/Navbar/Navbar'

import Text from '../../Components/Text/Text'
import Image from '../../Components/Image/Image'
import EachProduct from '../../Components/EachProduct/EachProduct'
import Footer from '../../Components/Footer/Footer'



export default function ProductPage() {

  const [isChecked, setIsChecked] = useState(false);

  const handleGasoline = () => {
    setIsChecked((prevChecked) => !prevChecked);
    setIsCheckedDiesel(false);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setIsCheckedFlush(false);
  };

  // Diesel useState

  const [isCheckedDiesel, setIsCheckedDiesel] = useState(false);

  const handleDiesel = () => {
    setIsChecked(false);
    setIsCheckedDiesel((prevChecked) => !prevChecked);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setIsCheckedFlush(false);

  };

  // Gear useState

  const [isCheckedGear, setIsCheckedGear] = useState(false);

  const handleGear = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false);
    setIsCheckedGear((prevChecked) => !prevChecked);
    setIsCheckedTransmission(false);
    setIsCheckedFlush(false);
    if (isCheckedGear) {
      setIsCheckedOneL(false);
    }
    if (!isCheckedGear) {
      setIsCheckedOneL(true);
    }

    
  };

  // Transmission useState

  const [isCheckedTransmission, setIsCheckedTransmission] = useState(false);

  const handleTransmission = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false);
    setIsCheckedGear(false);
    setIsCheckedTransmission((prevChecked) => !prevChecked);
    setIsCheckedFlush(false);
    if (isCheckedTransmission) {
      setIsCheckedOneL(false);
    }
    if (!isCheckedTransmission) {
      setIsCheckedOneL(true);
    }
  };

  // English Flush useState

  const [isCheckedFlush, setIsCheckedFlush] = useState(false);

  const handleFlush = (event) => {
    setIsChecked(false);
    setIsCheckedDiesel(false);
    setIsCheckedGear(false);
    setIsCheckedTransmission(false);
    setIsCheckedFlush((prevChecked) => !prevChecked);

    if (isCheckedFlush) {
      setIsCheckedFourL(false);
    }
    if (!isCheckedFlush) {
      setIsCheckedFourL(true);
    }
  };



  // ONE Letter useState

  const [isCheckedOneL, setIsCheckedOneL] = useState(false);

  const handleOneL = () => {
    setIsCheckedOneL((prevChecked) => !prevChecked);
    setIsCheckedFourL(false);
    setIsCheckedFiveL(false);
  };

  // Four Letter useState

  const [isCheckedFourL, setIsCheckedFourL] = useState(false);

  const handleFourL = () => {
    setIsCheckedOneL(false);
    setIsCheckedFourL((prevChecked) => !prevChecked);
    setIsCheckedFiveL(false);
    
  };

  // Five Letter useState

  const [isCheckedFiveL, setIsCheckedFiveL] = useState(false);

  const handleFiveL = (event) => {
    setIsCheckedOneL(false);
    setIsCheckedFourL(false);
    setIsCheckedFiveL((prevChecked) => !prevChecked);
  };


  return (
    <div className='ProductPage'>
      <div className="ProductPAgeNAvbar d-flex justify-content-center">
        <Navbar />
      </div>

      {/************************* 
         * SECTION 2
        Best Product  Section
       * ***************************** */}

      <div className="bestProductClass">
        <div className="row m-0 p-0" >
          <div className="col-md-5 d-flex align-items-center">
            <div className='leftSec mb-5'>
              <div className="BestProductHead">
                <Text text="best-in-class <br>
                  lubricant <span> products </span>" Margin="0px 0px 25px 0px" />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="RightSec">
              <Image Src="/images/bestProduct.webp" />
            </div>
          </div>
        </div>
      </div>

      {/************************* 
         * SECTION 3
        Side Bar
       * ***************************** */}
      <div className="ProductDetailList d-flex">
        <div className="FilerSideBar">
          <Text text=" FILTER BY" Color="#273889" FontSize="2rem" FontWeight="600" Margin="0px 0px 45px 15px" />

          <div className="SizeFilter">
            <Text text="Size" Color="black" FontSize="1.8rem" FontWeight="600" Margin="0px 0px 25px 0px" />
            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedOneL} onChange={handleOneL} disabled={isCheckedFlush }  type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> 1 LT </label>
            </div>
            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedFourL} onChange={handleFourL} disabled={isCheckedGear || isCheckedTransmission }    type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> 4 LT </label>
            </div>
            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedFiveL} onChange={handleFiveL} disabled={isCheckedGear || isCheckedTransmission || isCheckedFlush } type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> 5 LT </label>
            </div>
          </div>

          <div className="SizeFilter">
            <Text text="Categories " Color="black" FontSize="1.8rem" FontWeight="600" Margin="0px 0px 25px 0px" />

            {/* Gasoline check box */}

            <div className='OneLT d-flex align-items-center'>
              <input checked={isChecked} onChange={handleGasoline} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> Gasoline Engine </label>
            </div>

            {/* Diesel check box */}

            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedDiesel} onChange={handleDiesel} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1">Diesel Engine</label>
            </div>

            {/* Gear check box */}

            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedGear} onChange={handleGear} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> Gear Oils </label>
            </div>

            {/* Transmission check box */}

            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedTransmission} onChange={handleTransmission} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> Transmission Oils </label>
            </div>

            {/* Engine Flush check box */}

            <div className='OneLT d-flex align-items-center'>
              <input checked={isCheckedFlush} onChange={handleFlush} type="checkbox" id="vehicle1" name="vehicle1" value="Bike" class="abc" />
              <label for="vehicle1"> Engine Flush </label>
            </div>

          </div>

        </div>

        {/* GASOLINE ENGINE OIL */}

        <div className="ProductsList" >

          {isChecked &&
            <div className="gasoline" id='gasoline'>
              <div className="ProductHeding" >
                <Text text="Gasoline Engine" Color="#283989" FontSize="2rem" FontWeight="600" />
              </div>


              {isCheckedOneL &&
                <>
                  <div className="ProductItems">
                    <div className="textnoitem">
                      <Text text="No product of the Gasoline category is available in one litter" Color="black" />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>
                </>

              }
              {isCheckedFourL &&
                <>
                  <div className="ProductItems">
                    <div className="textnoitem">
                      <Text text="No product of the Gasoline category is available in four litter" Color="black" />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>

                </>
              }
              {isCheckedFiveL &&
                <>
                  <div className="ProductItems">
                    <div className="ProductItemsEach" id="oneL">
                      <EachProduct RouteLink="/0w20" Src="/images/0W20-5L.webp " ProductCategory="Gasoline Engine" ProductName="<span>0W-20</span> Hybrid Synthetic" ProductApi="API SP" ProductDatail="Recommended for modern petrol engines. It provides outstanding engine... <span>cleanliness, wear protection, strong durability, and Fuel Economy.</span> " />
                    </div>

                    <div className="ProductItemsEach">
                      <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                    </div>

                    <div className="ProductItemsEach">
                      <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                    </div>
                  </div>
                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>

                </>
              }

              {/* IF SIZE IS NOT SELECTED THEN ALL PRODUC WILL SHOW */}
              {
                !isCheckedOneL && !isCheckedFourL && !isCheckedFiveL &&
                <>
                  <div className="ProductItems">

                    <div className="ProductItemsEach" id="oneL">
                      <EachProduct RouteLink="/0w20" Src="/images/0W20-5L.webp " ProductCategory="Gasoline Engine" ProductName="<span>0W-20</span> Hybrid Synthetic" ProductApi="API SP" ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness... " />
                    </div>

                    <div className="ProductItemsEach">
                      <EachProduct RouteLink="/5w20" Src="/images/5W20-5L.webp" ProductCategory="Gasoline Engine" ProductName="<span>5W-20 </span> Celeritas plus Fully synthetic" ProductApi="API SP" ProductDatail="This oil significantly reduces wear and ensures fuel savings and... <span>lower CO2 emissions. It reduces soot and deposits. Suitable for hybrid cars.</span> " />
                    </div>

                    <div className="ProductItemsEach">
                      <EachProduct RouteLink="/5w30" Src="/images/empty.png" ProductCategory="Gasoline Engine" ProductName="<span>5W-30 </span> Celeritas plus Fully synthetic" ProductApi="API SP" ProductDatail="This oil significantly reduces wear and ensures fuel savings and... <span>lower CO2 emissions. It reduces soot and deposits. Suitable for hybrid cars.</span> " />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>
                </>
              }
            </div>}


          {/* Diesel ENGINE OIL */}


          {isCheckedDiesel &&
          <>
            <div className="diesel" id="diesel" >
              <div className="ProductHeding" >
                <Text text="Diesel Engine" Color="#283989" FontSize="2rem" FontWeight="600" />
              </div>
              </div>

                {isCheckedOneL &&
                  <>

                    <div className="ProductItems">
                      <div className="textnoitem">
                        <Text text="No product of the diesel category is available in four litter" Color="black" />
                      </div>
                    </div>

                    {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>

                  </>

                }
                {isCheckedFourL &&
                  <>

                    <div className="ProductItems">
                      <div className="textnoitem">
                        <Text text="No product of the diesel category is available in four litter" Color="black" />
                      </div>
                    </div>

                    {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>

                  </>
                }

                {isCheckedFiveL &&
                  <>
                    <div className="ProductItems" id="dieselcheck">
                    <div className="ProductItemsEach">
                      <EachProduct RouteLink="/hd50" Src="/images/HD50-5L.webp" ProductCategory="Diesel Engine" ProductName="<span> HD 50 </span> Fleet X " ProductApi="API CF" ProductDatail="HD50 is performance heavy-duty engine oil, primarily intended for use... <span>in gasoline  automotive engines of passenger cars, commercial vehicles and farm equipment.</span> " />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>

                  </>
                }

              {
                !isCheckedOneL && !isCheckedFourL && !isCheckedFiveL &&
                <>
                  <div className="ProductItems" id="dieselcheck">
                    <div className="ProductItemsEach">
                      <EachProduct RouteLink="/hd50" Src="/images/HD50-5L.webp" ProductCategory="Diesel Engine" ProductName="<span> HD 50 </span> Fleet X " ProductApi="API CF" ProductDatail="HD50 is performance heavy-duty engine oil, primarily intended for use... <span>in gasoline  automotive engines of passenger cars, commercial vehicles and farm equipment.</span> " />
                    </div>
                  </div>

                  {/* Compatable for both Diesel and gasoline */}

                  <div className="Compatable">
                    <div className="ProductHeding" >
                      <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                    </div>
                    <div className="ProductItems">

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                      </div>

                      <div className="ProductItemsEach">
                        <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                      </div>

                    </div>
                  </div>
                </>
              }

            </>
          }

          {/************* ***********************8
           * *********** Gear OIL********************
           * *********************************/}

          {isCheckedGear &&
            <div className="Gear" id="gearoil">
              <div className="ProductHeding" >
                <Text text="Gear Oils" Color="#283989" FontSize="2rem" FontWeight="600" />
              </div>
              <div className="ProductItems ">
                <div className="ProductItemsEach sae90">
                  <EachProduct RouteLink="/sae90" Src="/images/GEAR OIL-1L.webp" ProductCategory="Gear Oils" ProductName="<span> SAE 90  </span>" ProductDatail="SAE 90 gear oil is a premium quality lubricant designed for use in..." />
                </div>
                <div className="ProductItemsEach sae90">
                  <EachProduct RouteLink="/sae90" Src="/images/empty.png" ProductCategory="Gear Oils" ProductName="<span> SAE 140  </span>" ProductDatail="SAE 90 gear oil is a premium quality lubricant designed for use in..." />
                </div>
                <div className="ProductItemsEach">
                  <EachProduct RouteLink="/cvtoil" Src="/images/CVT-1L.webp" ProductCategory="Diesel Engine" ProductName="<span> CVT Fluid  </span> Full Synthetic" ProductDatail="Full synthetic fluid designed for most CVT (Continuously Variable Transmission)...<span>gearboxes with steel push-belts or chains. It offers superior anti-wear protection and stable friction characteristics.</span> " />
                </div>

              </div>
            </div>
          }

          {/* Transmission OIL */}

          {isCheckedTransmission &&
            <div className="Gear" id="Transmissionoil">
              <div className="ProductHeding" >
                <Text text="Transmission Oils" Color="#283989" FontSize="2rem" FontWeight="600" />
              </div>
              <div className="ProductItems">
                <div className="ProductItemsEach">
                  <EachProduct RouteLink="/dexron" Src="/images/DEXRON-III-1L.webp" ProductCategory="Diesel Engine" ProductName="<span> DEXRON III  </span> Automatic Plus Transmission fluid" ProductDatail="This ATF Automatic Transmission Fluid has a high viscosity index...<span>to ensure great fluidity on engine start and guaranteed component protection at high temperatures.</span> " />
                </div>
              </div>
            </div>
          }

          {/* Engine Flush Oil */}

          {isCheckedFlush &&
            <div className="Gear" id="Flushoil">
              <div className="ProductHeding" >
                <Text text="Engine Flush" Color="#283989" FontSize="2rem" FontWeight="600" />
              </div>
              <div className="ProductItems">
                <div className="ProductItemsEach">
                  <EachProduct RouteLink="/enginflush" Src="/images/ENGINE FLUSH-4L.webp" ProductCategory="Diesel Engine" ProductName="<span> ENGINE FLUSH </span>" ProductDatail="Engine Flush is a specially formulated combination of additives and a carrier...<span>fluid. In combination with the carrier fluid, the highly effective detergent and dispersant additives dissolve sludge and lacquer formers.</span> " />
                </div>
              </div>
            </div>
          }



          {/* show all product if no check box is checked */}

          {!isChecked && !isCheckedDiesel && !isCheckedGear && !isCheckedTransmission && !isCheckedFlush &&
            <>
              {/* GASOLINE OIL IF NO CHECK BOX IS SELECTED */}
              <div className="gasoline" id='gasoline'>
                <div className="ProductHeding" >
                  <Text text="Gasoline Engine" Color="#283989" FontSize="2rem" FontWeight="600" />
                </div>

                <div className="ProductItems">

                  <div className="ProductItemsEach" id="oneL">
                    <EachProduct RouteLink="/0w20" Src="/images/0W20-5L.webp " ProductCategory="Gasoline Engine" ProductName="<span>0W-20</span> Hybrid Synthetic" ProductApi="API SP" ProductDatail="Recommended for modern petrol engines. It provides outstanding engine cleanliness... " />
                  </div>

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/5w20" Src="/images/5W20-5L.webp" ProductCategory="Gasoline Engine" ProductName="<span>5W-20 </span> Celeritas plus Fully synthetic" ProductApi="API SP" ProductDatail="This oil significantly reduces wear and ensures fuel savings and... <span>lower CO2 emissions. It reduces soot and deposits. Suitable for hybrid cars.</span> " />
                  </div>

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/5w30" Src="/images/empty.png" ProductCategory="Gasoline Engine" ProductName="<span>5W-30 </span> Celeritas plus Fully synthetic" ProductApi="API SP" ProductDatail="This oil significantly reduces wear and ensures fuel savings and... <span>lower CO2 emissions. It reduces soot and deposits. Suitable for hybrid cars.</span> " />
                  </div>

                </div>
              </div>

              {/* Diesel ENGINE OIL IF NO CHECK BOX IS SELECTED */}

              <div className="diesel" id="diesel">
                <div className="ProductHeding" >
                  <Text text="Diesel Engine" Color="#283989" FontSize="2rem" FontWeight="600" />
                </div>
                <div className="ProductItems">

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/hd50" Src="/images/HD50-5L.webp" ProductCategory="Diesel Engine" ProductName="<span> HD 50 </span> Fleet X " ProductApi="API CF" ProductDatail="HD50 is performance heavy-duty engine oil, primarily intended for use... <span>in gasoline  automotive engines of passenger cars, commercial vehicles and farm equipment.</span> " />
                  </div>
                </div>
              </div>

              {/* Compatable for both Diesel and gasoline */}

              <div className="Compatable">
                <div className="ProductHeding" >
                  <Text text="Compatabel for both Diesel/Gasoline" Color="#283989" FontSize="2rem" FontWeight="600" />
                </div>
                <div className="ProductItems">

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/10w30" Src="/images/empty.png" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W30 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                  </div>

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/10w40" Src="/images/10W40-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 10W40 </span> Saphire Semi-Synthetic" ProductApi="API SL/CF" ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected...<span>highly refined base oils. Thanks to its optimum viscosity index it can be used all year round.</span> " />
                  </div>

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/15w40" Src="/images/15W40-5L..webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 15W40 </span> Speed X Semi-Synthetic" ProductApi="API SL/CI" ProductDatail="This is a high-performance mineral diesel engine oil that delivers reliable...<span>performance in a broad range of heavy-duty applications.</span>  " />
                  </div>

                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/20w50" Src="/images/20W50-5L.webp" ProductCategory="Gasoline/Diesel Engine" ProductName="<span> 20W50 </span> Golden power Heavy duty " ProductApi="API SL/CF" ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides...<span>very good resistance to oxidation and has optimal detergent and dispersion properties.</span>  " />
                  </div>

                </div>
              </div>


              {/* Engine Flush OIL IF NO CHECK BOX IS SELECTED */}

              <div className="Gear" id="Flushoil">
                <div className="ProductHeding" >
                  <Text text="Engine Flush" Color="#283989" FontSize="2rem" FontWeight="600" />
                </div>
                <div className="ProductItems">
                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/enginflush" Src="/images/ENGINE FLUSH-4L.webp" ProductCategory="ALL Engine" ProductName="<span> ENGINE FLUSH </span>" ProductDatail="Engine Flush is a specially formulated combination of additives and a carrier...<span>fluid. In combination with the carrier fluid, the highly effective detergent and dispersant additives dissolve sludge and lacquer formers.</span> " />
                  </div>
                </div>
              </div>


              {/* Gear OIL IF NO CHECK BOX IS SELECTED */}

              <div className="Gear" id="gearoil">
                <div className="ProductHeding" >
                  <Text text="Gear Oils" Color="#283989" FontSize="2rem" FontWeight="600" />
                </div>
                <div className="ProductItems ">
                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/cvtoil" Src="/images/CVT-1L.webp" ProductCategory="Gear Engine" ProductName="<span> CVT Fluid  </span> Full Synthetic" ProductDatail="Full synthetic fluid designed for most CVT (Continuously Variable Transmission)...<span>gearboxes with steel push-belts or chains. It offers superior anti-wear protection and stable friction characteristics.</span> " />
                  </div>
                  <div className="ProductItemsEach sae90">
                    <EachProduct RouteLink="/sae90" Src="/images/GEAR OIL-1L.webp" ProductCategory="Gear Oils" ProductName="<span> SAE 90  </span>" ProductDatail="SAE 90 gear oil is a premium quality lubricant designed for use in differentials, which..." />
                  </div>
                  <div className="ProductItemsEach sae90">
                    <EachProduct RouteLink="/sae140" Src="/images/empty.png" ProductCategory="Gear Oils" ProductName="<span> SAE 140  </span>" ProductDatail="SAE 90 gear oil is a premium quality lubricant designed for use in differentials, which..." />
                  </div>

                </div>
              </div>

              {/* Transmission OIL IF NO CHECK BOX IS SELECTED */}

              <div className="Gear" id="Transmissionoil">
                <div className="ProductHeding" >
                  <Text text="Transmission Oils" Color="#283989" FontSize="2rem" FontWeight="600" />
                </div>
                <div className="ProductItems">
                  <div className="ProductItemsEach">
                    <EachProduct RouteLink="/dexron" Src="/images/DEXRON-III-1L.webp" ProductCategory="Transmission Engine" ProductName="<span> DEXRON III  </span> Automatic Plus Transmission fluid" ProductDatail="This ATF Automatic Transmission Fluid has a high viscosity index...<span>to ensure great fluidity on engine start and guaranteed component protection at high temperatures.</span> " />
                  </div>
                </div>
              </div>



            </>
          }
        </div>




      </div>

      {/************************* 
         * SECTION 3
        FOOTER
       * ***************************** */}
      <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />
    </div>
  )
}
