import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function EnginFlush() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="/images/ENGINE FLUSH-4L.webp"
            ProductImage2="/images/ENGINE FLUSH-4L.webp"
            ProductImage3="/images/ENGINE FLUSH-4L.webp"
            ProductCategory="Diesel Engine"
            ProductName="<span> ENGINE FLUSH </span>"
            ProductDatail="Engine Flush is a specially formulated combination of additives and a carrier fluid. In combination with the carrier fluid, the highly effective detergent and dispersant additives dissolve sludge and lacquer formers."
            ProductSize1="4L"

            // PRODUCT INFORMATION

            productInfo1= "Our engine flush is a specialized liquid designed for cleaning and flushing out the oil circuits of gasoline and diesel engines by breaking down and removing deposits of sludge, dirt, and other contaminants. By removing these deposits, engine flush can help to keep the engine operating smoothly and efficiently."

            // HOW TO USE

            textuse1= "Add engine flush to the engine oil before an oil change."
            textuse2= "Start the engine and let it idle for 20-30 minutes to allow the engine flush to circulate throughout the engine."
            textuse3="Do not drive the vehicle during this time."
            textuse4="After the engine has idled for the recommended time, turn off the engine and allow it to cool for 10-15 minutes."
            textuse5="Drain the engine oil and replace it with fresh oil and a new oil filter."
            textuse6="Check the oil level and top off if necessary."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

            
            // RELATED PRODUCTS

            SliderProductCategory2="Gasoline/Diesel Engine"
            SliderProductCategory1="Diesel Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage2="/images/empty.png"
            SliderProductImage1="/images/HD50-5L.webp"
            SliderProductImage3="/images/10W40-5L.webp"
            SliderProductImage4="/images/15W40-5L..webp"

            SliderProductName2="<span> 10W-30 </span> GOLDEN POWER"
            SliderProductName1="<span> HD 50 </span> Fleet X"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi2="API SL/CF"
            SliderProductApi1="API CF"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail2="Introducing a premium semi-synthetic lubricant formulated... "
            SliderProductDatail1="HD 50 is performance heavy-duty engine oil, primarily..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/10w30"
            SliderRouteLink2="/hd50"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />        
    </div>
  )
}
