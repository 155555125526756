import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Image from '../../Components/Image/Image'
import "../../Styles/ContactUs.css"
import Footer from '../../Components/Footer/Footer'

import PageDetail from '../../Components/PageDetail/PageDetail'
import MyButton from '../../Components/Button/Button'

export default function ContactUs() {
  return (
    <div className='AboutUs'>
        <div className="AboutUsNAv d-flex justify-content-center">
            <Navbar /> 
        </div>

        {/************************* 
         * SECTION 2
        Wide Range Cetagories Section
       * ***************************** */}
        <div className="ContactUS">
          <PageDetail Color="black"  text="Categories" text1=" WIDE RANGE OF LUBRICANT" text2="SOLUTIONS" text3 ="Company dedicated to providing the highest quality products for all your engine needs. Our advanced formulation is specially designed to deliver maximum protection against wear and tear, resulting in a smoother, more efficient engine performance. We pride ourselves on using only the finest ingredients and adhering to strict manufacturing standards to ensure that every bottle of PUREOIL meets our uncompromising quality standards. " />
        </div>

        {/************************* 
         * SECTION 3
        About Section
       * ***************************** */}
        <div className="WriteUs" id='contactform'>
          <PageDetail  text="Write Us" text1=" HAVE SOMETHING  <br> IN YOUR" text2="MIND?" text3 ="Our customer service representatives are available Monday through Friday from 9am to 5pm to assist you with any inquiries you may have. We are committed to providing our customers with the highest level of service and support, and we look forward to hearing from you soon. <br> <br >Thank you for choosing PUREOIL." />
        </div>

        {/************************* 
         * SECTION 4
        About Section
       * ***************************** */}  
       <div className="ContactForm" >
        <form action="">
          <div className="formData">
            <div className="username">
              <label for="">your name</label>
              <input type="text" name="" id=""  placeholder='Enter your name'/>
            </div>
            <div className="useremail">
            <label for="">email</label>
              <input type="text" name="" id=""  placeholder='Enter your email address' />
            </div>
            <div className="usermessage">
            <label for="">message</label>
              <textarea name="" id="" cols="30" rows="5" placeholder='Your message here...'></textarea>
            </div>
          </div>
          <div className="submitBtn text-center">
            <MyButton text="Submit"  BGcolor="#273889" Color="white" Padding= "10px 30px 10px 30px" Margin="50px 0px 0px 0px" />
          </div>
        </form>
       </div>


        {/************************* 
         * SECTION 4
        FOOTER
       * ***************************** */}

          <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />


    </div>
  )
}
