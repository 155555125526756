import React from 'react'
import Button from '../Button/Button'
import Text from '../Text/Text'
import Image from '../Image/Image'
import "../Footer/Footer.css"
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom'

export default function Footer({Color, Src1, Src2, Src3,Src4,Src5}) {


  return (
    <div className="Footer">
          <div className="footerTop text-center mt-5 pt-5 pb-5">
            <Text text="GET IN TOUCH VIA OUR SOCIAL MEDIA PROFILES" Color={Color}/>
            <div className="socialMedia d-flex justify-content-center mt-4">
              <Link to="mailto:pure.oil2012@gmail.com" > <img src={Src1} alt="" /> </Link>
              <Link to="https://www.facebook.com/profile.php?id=100076543406719&mibextid=LQQJ4d"> <img src={Src2} alt="" /> </Link>
              <Link to="https://instagram.com/pureoillubricants?igshid=OGQ5ZDc2ODk2ZA=="> <img src={Src3} alt="" /> </Link>
              <Link to="https://www.tiktok.com/@pureoillubricants?_t=8bP6AkZ7NSf&_r=1"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/></svg></Link>

            </div>
          </div>
          <div className="footerBottom">
            <div className="row m-0 p-0" >
              <div className="col-md-5">
                <div className="FotleftSec">
                  <Image Src="/images/sitelogo.png" Width="30%" Margin="0px 0px 0px 0px " />
                  <Text text="Phone: +97148819717 <br> Email: info@pureoil.com <br> Address: Mina Jebel Ali - National Industries Park <br> Dubai - United Arab Emirates" Color="black" Margin="50px 0px 0px 0px" />
                </div>
              </div>
              <div className="col-md-7">
                <div className="FotRightSec d-flex justify-content-between">
                  <div className="FotProducts">
                    <Text text="PRODUCTS"  Color="black" FontWeight="600" Margin="0px 0px 10px 0px"    />
                    <HashLink smooth to="/product#gasoline"> 
                      <Button text="Gasoline Engine Oil" Color="black" TextTransform="capitalize" />
                    </HashLink>
                    <HashLink smooth to="/product#diesel">  
                      <Button text="Diesel Engine Oil" Color="black" TextTransform="capitalize" />
                    </HashLink>
                    <HashLink smooth to="/product#gearoil"> 
                      <Button text="Gear Oils " Color="black" TextTransform="capitalize" />
                    </HashLink>
                    <HashLink smooth to="/product#Transmissionoil"> 
                      <Button text="Transmission Oil" Color="black" TextTransform="capitalize" />
                    </HashLink>
                    <HashLink smooth to="/product#Flushoil"> 
                      <Button text="Engine Flush " Color="black" TextTransform="capitalize" />
                    </HashLink>
                  </div>
                  <div className="FotAbout">
                    <Text text="ABOUT US"  Color="black" FontWeight="600" Margin="0px 0px 10px 0px"    />
                    <HashLink smooth to="/aboutus#mission"> 
                    <Button text="our mission" Color="black" TextTransform="capitalize" />
                    </HashLink>
                    <HashLink smooth to="/aboutus#goal"> 
                    <Button text="our goal" Color="black" TextTransform="capitalize"/>
                    </HashLink>
                    {/* <Button RouteLink="/"   text="term of use" Color="black" TextTransform="capitalize"/> */}
                  </div>
                  <div className="FotContact">
                    <Text text="CONTACT US"  Color="black" FontWeight="600" Margin="0px 0px 10px 0px"    />
                    <Button RouteLink="https://www.tiktok.com/@pureoillubricants?_t=8bP6AkZ7NSf&_r=1"   text="gmail" Color="black" TextTransform="capitalize"/>
                    <Button RouteLink="https://www.facebook.com/profile.php?id=100076543406719&mibextid=LQQJ4d"   text="facebook" Color="black" TextTransform="capitalize"/>
                    <Button RouteLink="https://instagram.com/pureoillubricants?igshid=OGQ5ZDc2ODk2ZA=="   text="instagram" Color="black" TextTransform="capitalize"/>
                    <HashLink smooth to="/contactus#contactform"> 
                    <Button   text="write us" Color="black" TextTransform="capitalize"/>
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <Text text="Copyrights @ QRYPTON" Color="black" Margin="50px 0px 0px 0px" FontSize="1.5rem"  />
              </div>
            </div>
          </div>
        </div>
        
  )
}
