import React from 'react'
import Text from '../Text/Text'
import Image from '../Image/Image'
import { Link } from 'react-router-dom';
import "../NavbarButtons/NavbarButtons.css"


export default function NavbarButtons({text, RouterLink}) {
    
  return (
    <div className='NavbarButtons'>
      <Link to={RouterLink}>
      <Text text={text} TextTransform="uppercase"  FontWeight="700" FontSize="1.5rem" Color="black" />
        {/* <Image Src="/images/drop-down.webp" /> */}
        <p className='ItemBorder'></p>
      </Link>
      
    </div>
  )
}
