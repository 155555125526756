import React, { useState } from 'react'
import "../SingleProduct/SingleProduct.css"

import Image from '../Image/Image'
import Text from '../Text/Text'
import { Button } from 'bootstrap'
import MyButton from '../Button/Button'
import EachProduct from '../EachProduct/EachProduct'

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";



export default function SingleProduct({ ProductImage, ProductImage2,ProductImage3 ,ProductImage4, ProductCategory, ProductName, ProductApi, ProductDatail, ProductSize1, ProductSize2, ProductSize3, ProductSize4,
  textuse1, textuse2, textuse3, textuse4, textuse5, textuse6,
  SliderProductImage1, SliderProductImage2, SliderProductImage3, SliderProductImage4,
  SliderProductCategory1, SliderProductCategory2, SliderProductCategory3, SliderProductCategory4,
  SliderProductName1, SliderProductName2, SliderProductName3, SliderProductName4,
  SliderProductApi1, SliderProductApi2, SliderProductApi3, SliderProductApi4,
  SliderProductDatail1, SliderProductDatail2, SliderProductDatail3, SliderProductDatail4,
  safetyInfo1, safetyInfo2, safetyInfo3, safetyInfo4, safetyInfo5, safetyInfo6,
  productInfo1,
  SliderRouteLink1, SliderRouteLink2, SliderRouteLink3, SliderRouteLink4
}) {

  const SwiperCarousel = () => {



    return (
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={25}
        slidesPerView={2.4}
        breakpoints={{
          // Responsive breakpoints
          150: {
            slidesPerView: 1.3, // Number of slides to show on mobile
            spaceBetween: 25, // Space between slides on mobile
          },
          820: {
            slidesPerView: 2.4, // Number of slides to show on mobile
            spaceBetween: 15, // Space between slides on mobile
          }

        }}

        pagination={{
          type: "bullets",
          spaceBetween: 70,
        }}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>

          <EachProduct RouteLink={SliderRouteLink1} Src={SliderProductImage1} ProductCategory={SliderProductCategory1} ProductName={SliderProductName1} ProductApi={SliderProductApi1} ProductDatail={SliderProductDatail1} />
        </SwiperSlide>
        <SwiperSlide>
          <EachProduct RouteLink={SliderRouteLink2} Src={SliderProductImage2} ProductCategory={SliderProductCategory2} ProductName={SliderProductName2} ProductApi={SliderProductApi2} ProductDatail={SliderProductDatail2} />
        </SwiperSlide>
        <SwiperSlide>
          <EachProduct RouteLink={SliderRouteLink3} Src={SliderProductImage3} ProductCategory={SliderProductCategory3} ProductName={SliderProductName3} ProductApi={SliderProductApi3} ProductDatail={SliderProductDatail3} />
        </SwiperSlide>
        <SwiperSlide>
          <EachProduct RouteLink={SliderRouteLink4} Src={SliderProductImage4} ProductCategory={SliderProductCategory4} ProductName={SliderProductName4} ProductApi={SliderProductApi4} ProductDatail={SliderProductDatail4} />
        </SwiperSlide>
        <style>
          {`.swiper-pagination{
            color: red;
          },
          .swiper-pagination-bullet {
            color: red;
            background-color: red;
          }
          .swiper{
            height: 60vh;
          }`}
        </style>
      </Swiper>
    );
  };


  const [activeDiv, setActiveDiv] = useState('div1');

  const handleButtonClick = (divId) => {
    setActiveDiv(divId);
  }

  const [activePic, setActivePic] = useState('Pic1');

  const handleButtonClicks = (divId) => {
    setActivePic(divId);
  };

  return (
    <>
      <div className='SingleProduct'>
        <div className="slideritems d-flex">
          <div className="sliderimage">

            <div>

              {activePic === 'Pic1' && <div id="Pic1">
                <Image Src={ProductImage} Width="80%" />
              </div>}
              {activePic === 'Pic2' && <div id="Pic2">
                <Image Src={ProductImage2} Width="80%" />
              </div>}
              {activePic === 'Pic3' && <div id="Pic3">
                <Image Src={ProductImage3} Width="80%" />
              </div>}
              {activePic === 'Pic4' && <div id="Pic4">
                <Image Src={ProductImage4} Width="80%" />
              </div>}

              <style>
                {`
          button {
            width: auto;
            height: auto;
            border: none;
            border-radius: 0;
            margin: 0px 5px 0px 5px;
            padding: 10px 15px;
            font-size: 2rem;
            text-transform: none;
            color: black;
            font-weight: 400;
            background-color: white;
          }
          
          .active {
            background-color: #273889;
            color: white;
          }
        `}
              </style>
            </div>
          </div>
          <div className="productdetail">
            <Text text={ProductCategory} Color="#A9A9A9" FontSize="2.5rem" />
            <Text text={ProductName} Color="black" FontSize="3.5rem" />
            {
              ProductApi ?
                <Text text={ProductApi} Color="black" FontSize="2.5rem" />
                :
                ""
            }
            <Text text={ProductDatail} Color="black" Margin="10px 0px 0px 0px" />
            <div className="Specification">
              <Text text="Size Guide" Color="#A9A9A9" Margin="10px 0px 0px 0px" />
              <div className="liters d-flex">

                {
                  ProductSize1 ?
                    <>
                      <button
                        className={activePic === 'Pic1' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic1')}
                      >
                        {ProductSize1}
                      </button>
                      {/* <Text text={ProductSize1} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </>
                    :
                    ""
                }
                {

                  ProductSize2 ?
                    <>
                      <button
                        className={activePic === 'Pic2' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic2')}
                      >
                        {ProductSize2}
                      </button>
                      {/* <Text text={ProductSize2} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </>


                    :
                    ""
                }
                {
                  ProductSize3 ?
                    <>
                      <button
                        className={activePic === 'Pic3' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic3')}
                      >
                        {ProductSize3}
                      </button>
                      {/* <Text text={ProductSize3} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </> :
                    ""
                }
                {
                  ProductSize4 ?
                    <>
                      <button
                        className={activePic === 'Pic4' ? 'active' : ''}
                        onClick={() => handleButtonClicks('Pic4')}
                      >
                        {ProductSize4}
                      </button>
                      {/* <Text text={ProductSize3} Color="black" BgColor="white" Margin="0px 5px 0px 5px" Padding="8px 10px 8px 10px" /> */}

                    </> :
                    ""
                }
              </div>
            </div>
          </div>
        </div>
        <div className="ProductInfoBtn">
          <button className={activeDiv === 'div1' ? 'active' : ''} SingleProductInfo onClick={() => handleButtonClick('div1')}>
            PRODUCT INFORMATION
          </button>
          <button className={activeDiv === 'div2' ? 'active' : ''} SingleProductInfo onClick={() => handleButtonClick('div2')}>
            DIRECTION OF USE
          </button>
          <button className={activeDiv === 'div3' ? 'active' : ''} SingleProductInfo onClick={() => handleButtonClick('div3')}>
            SAFETY INFORMATION
          </button>
        </div>

        {activeDiv === 'div1' &&
          <div className="HowToUse" id='div1' >
            <Text text="Product Information:" Color="#273889" FontWeight="500" Margin="0px 0px 30px 0px" />
            <Text text={productInfo1} Color="black" Margin="0px 0px 0px 20px" />
          </div>}

        {activeDiv === 'div2' &&
          <div className="HowToUse" id="div2" >
            <Text text="Directions for Use: " Color="#273889" FontWeight="500" />
            <ul>
              <li>
                <Text text={textuse1} Color="black" />
              </li>
              <li>
                <Text text={textuse2} Color="black" />
              </li>
              <li>
                <Text text={textuse3} Color="black" />
              </li>
              <li>
                <Text text={textuse4} Color="black" />
              </li>
              <li>
                <Text text={textuse5} Color="black" />
              </li>
              {
                textuse6 ?
                  <li>
                    <Text text={textuse6} Color="black" />
                  </li>
                  :
                  ""
              }
            </ul>

          </div>}

        {activeDiv === 'div3' &&
          <div className="HowToUse" id="div3">
            <Text text="Safety Information:" Color="#273889" FontWeight="500" />
            <ul>
              <li>
                <Text text={safetyInfo1} Color="black" />
              </li>
              <li>
                <Text text={safetyInfo2} Color="black" />
              </li>
              <li>
                <Text text={safetyInfo3} Color="black" />
              </li>
              <li>
                <Text text={safetyInfo4} Color="black" />
              </li>
              <li>
                <Text text={safetyInfo5} Color="black" />
              </li>
              {
                safetyInfo6 ?
                  <li>
                    <Text text={safetyInfo6} Color="black" />
                  </li>
                  :
                  ""
              }

            </ul>

          </div>}






      </div>
      <div className="bestSellerSlider">
        <div className="sliderHead">
          <Text text="Related Products" Color="black" FontWeight="400" FontSize="3rem" />
        </div>
        <SwiperCarousel />
      </div>

      <style>
        {`
          .ProductInfoBtn button {
            color: black;
            background: #F4F4F4;
            font-size: 2rem;
            font-weight: 300;
            font-family: Titillium Web,sans-serif;
            border: none;
            padding: 10px 30px;
            margin: 0;
            border-radius: 10px;
            width: 100%;
          }
          
          .ProductInfoBtn button.active {
            background-color: #273889;
            color: white;
          }
        `}
      </style>




    </>


  )
}