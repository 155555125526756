import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil5W30() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
          ProductImage="/images/empty.png"
          ProductImage2="/images/empty.png"
          ProductImage3="/images/empty.png"
            ProductCategory="Gasoline Engine"
            ProductName="<span> 5W-30 </span>Celeritas plus Fully synthetic"
            ProductApi="API SP"
            ProductDatail="This oil reduces wear, enhances fuel efficiency, and lowers CO2 emissions while preventing soot and deposits. Ideal for hybrid cars."
            
            ProductSize1="5L"
            

            // PRODUCT INFORMATION

            productInfo1= "This advanced oil, with its low viscosity and exceptional fluidity, plays a pivotal role in reducing wear and promoting fuel efficiency, resulting in lower CO2 emissions. With its state-of-the-art formulation, this oil ensures an effortless cold start, minimizes wear, and enhances fuel economy"

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PUREOIL Car Engine Oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // safety info

            safetyInfo1= " Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Pour the PUREOIL Car Engine Oil into the engine."
            safetyInfo5="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo6="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses"

            
            // RELATED PRODUCTS

           SliderProductCategory1="Gasoline Engine"
           SliderProductCategory2="Gasoline Engine"
           SliderProductCategory3="Gasoline/Diesel Engine"
           SliderProductCategory4="Gasoline/Diesel Engine"

           SliderProductImage1="/images/0W20-5L.webp"
           SliderProductImage2="/images/5W20-5L.webp"
           SliderProductImage3="/images/10W40-5L.webp"
           SliderProductImage4="/images/15W40-5L..webp"

           SliderProductName1="<span>0W-20</span> Hybrid Synthetic "
           SliderProductName2="<span>5W-20 </span> Celeritas plus Fully synthetic"
           SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
           SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

           SliderProductApi1="API SP"
           SliderProductApi2="API SP"
           SliderProductApi3="API SL/CF"
           SliderProductApi4="API SL/CI"
                                 
           SliderProductDatail1="Recommended for modern petrol engines. It provides outstanding... "
           SliderProductDatail2="This oil significantly reduces wear and ensures fuel savings..."
           SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
           SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

           SliderRouteLink1="/0w20"
           SliderRouteLink2="/5w20"
           SliderRouteLink3="/10w40"
           SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />        
    </div>
  )
}
