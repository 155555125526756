import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil10W40() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="/images/10W40-5L.webp"
            ProductImage2="/images/10W40-5L.webp"
            ProductImage3="/images/10W40-5L.webp"
            ProductCategory="Gasoline/Diesel Engine"
            ProductName="<span> 10W40 </span> Saphire Semi-Synthetic"
            ProductApi="API SL/CF"
            ProductDatail="This is a semi-synthetic advanced lubricant based on carefully selected highly refined base oils. Thanks to its optimum viscosity index it can be used all year round."
            ProductSize1="1L"
            ProductSize2="4L"
            ProductSize3="5L"

            // PRODUCT INFORMATION

            productInfo1= "It is Ideal for most gasoline and diesel engines normally aspirated and supercharged. This oil has an optimum fluidity at low temperatures and a stable viscosity at high temperatures."

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PUREOIL Car Engine Oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // Safety Info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="Check the oil level with the dipstick and adjust if necessary."
            safetyInfo6="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

            
            // RELATED PRODUCTS

            SliderProductCategory1="Gasoline/Diesel Engine"
            SliderProductCategory2="Gasoline/Diesel Engine"
            SliderProductCategory3="Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage1="/images/empty.png"
            SliderProductImage2="/images/20W50-5L.webp"
            SliderProductImage3="/images/HD50-5L.webp"
            SliderProductImage4="/images/15W40-5L..webp"

            SliderProductName1="<span> 10W-30 </span> GOLDEN POWER"
            SliderProductName2="<span> 20W50 </span> Golden power Heavy duty"
            SliderProductName3="<span> HD 50 </span> Fleet X"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi1="API SL/CF"
            SliderProductApi2="API SL/CF"
            SliderProductApi3="API CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail1="Introducing a premium semi-synthetic lubricant formulated... "
            SliderProductDatail2="A very high-quality mineral engine oil for most 4-stroke..."
            SliderProductDatail3="HD 50 is performance heavy-duty engine oil, primarily intended..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/10w30"
            SliderRouteLink2="/20w50"
            SliderRouteLink3="/hd50"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />        
    </div>
  )
}
