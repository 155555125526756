import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import SingleProduct from '../../Components/SingleProduct/SingleProduct'

import "../../Styles/ProductDiscription.css"
import Footer from '../../Components/Footer/Footer'

export default function Oil20W50() {


  return (
    <div className='ProductDiscription'>
        <div className="productDiscriptionNav d-flex justify-content-center">
          <Navbar />
        </div>
        <div className='breadcrumb'></div>
        <div className="ProductDetail">
          <SingleProduct
            ProductImage="/images/20W50-5L.webp"
            ProductImage2="/images/20W50-5L.webp"
            ProductImage3="/images/20W50-5L.webp"
            ProductImage4="/images/20W50-5L.webp"
            ProductCategory="Gasoline/Diesel Engine"
            ProductName="<span> 20W50 </span> Golden power Heavy duty"
            ProductApi="API SL/CF"
            ProductDatail="A very high-quality mineral engine oil for most 4-stroke engines. Provides very good resistance to oxidation and has optimal detergent and dispersion properties."
            ProductSize1="1L"
            ProductSize2="2L"
            ProductSize3="5L"
            ProductSize4="20L"

            // PRODUCT INFORMATION

            productInfo1= "A very high-quality mineral engine oil, which is suitable for most 4-stroke gasoline and diesel cars and light trucks. By its selected composition, very good resistance to oxidation and optimal detergent and dispersion properties are obtained. It has suitable fluidity at low temperatures and a stable viscosity at high temperatures."

            // HOW TO USE

            textuse1= "Consult your car owner's manual for the recommended viscosity grade and oil specification."
            textuse2= "Turn off the engine and wait for a few minutes before changing the oil."
            textuse3="Drain the old oil and replace the oil filter."
            textuse4="Pour the PUREOIL Car Engine Oil into the engine."
            textuse5="Check the oil level with the dipstick and adjust if necessary."
            textuse6="Start the engine and let it run for a few minutes before checking for leaks."

            // Safety info

            safetyInfo1= "Avoid prolonged skin contact with the oil and wash thoroughly with soap and water if contact occurs."
            safetyInfo2= "Use in a well-ventilated area and avoid inhaling oil fumes or mist."
            safetyInfo3="Do not smoke or use open flames near the oil."
            safetyInfo4="Store the oil in a cool, dry, and well-ventilated area away from direct sunlight and heat sources."
            safetyInfo5="When handling the oil, wear appropriate personal protective equipment such as gloves and safety glasses."

            
            // RELATED PRODUCTS

            SliderProductCategory2="Gasoline/Diesel Engine"
            SliderProductCategory1="Diesel Engine"
            SliderProductCategory3="Gasoline/Diesel Engine"
            SliderProductCategory4="Gasoline/Diesel Engine"

            SliderProductImage2="/images/empty.png"
            SliderProductImage1="/images/HD50-5L.webp"
            SliderProductImage3="/images/10W40-5L.webp"
            SliderProductImage4="/images/15W40-5L..webp"

            SliderProductName2="<span> 10W-30 </span> GOLDEN POWER"
            SliderProductName1="<span> HD 50 </span> Fleet X"
            SliderProductName3="<span> 10W40 </span> Saphire Semi-Synthetic"
            SliderProductName4="<span> 15W40 </span> Speed X Semi-Synthetic"

            SliderProductApi2="API SL/CF"
            SliderProductApi1="API CF"
            SliderProductApi3="API SL/CF"
            SliderProductApi4="API SL/CI"
                                  
            SliderProductDatail2="Introducing a premium semi-synthetic lubricant formulated... "
            SliderProductDatail1="HD 50 is performance heavy-duty engine oil, primarily..."
            SliderProductDatail3="This is a semi-synthetic advanced lubricant based on carefully..."
            SliderProductDatail4="This is a high-performance mineral diesel engine oil that..."

            SliderRouteLink1="/10w30"
            SliderRouteLink2="/hd50"
            SliderRouteLink3="/10w40"
            SliderRouteLink4="/15w40"

            />
        </div>

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />        
    </div>
  )
}
