import React from 'react'
import Text from '../Text/Text'
import "../PageDetail/PageDetail.css"

export default function PageDetail({text, text1, text2, text3, Color}) {
  return (
    <div className="DetailsSec">
          <div className="row m-0 p-0" >
            <div className="col-md-6 col-sm-6">
              <div className='leftSec'>
              <Text text={text}  Color={Color} />
              <Text text={text1} Color={Color}/>
              <Text text={text2} Color={Color}/>
              <div className="border"></div>
              </div> 
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="RightSec">
                <p className='verticalBorder'></p>
                <Text text={text3} Color={Color}/>
              </div>
            </div>
          </div>
        </div>
  )
}
