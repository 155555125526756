import Landing from "./Pages/LandingPage/Landing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProductPage from "./Pages/ProductPage/ProductPage";
import AboutUs from "./Pages/AboutUsPage/AboutUs";
import ContactUs from "./Pages/ContactUsPage/ContactUs";

// PRODUCTS PAGES

import Oil0W20 from "./Pages/Products/0W-20";
import Oil5W20 from "./Pages/Products/5W-20";
import Oil10W40 from "./Pages/Products/10W-40";
import Oil15W40 from "./Pages/Products/15W-40";
import Oil20W50 from "./Pages/Products/20W-50";
import OilHD50 from "./Pages/Products/HD-50";
import EnginFlush from "./Pages/Products/EnginFlush";
import CvtOil from "./Pages/Products/CvtOil";
import Dexron from "./Pages/Products/Dexron";
import Sae90 from "./Pages/Products/Sae-90";
import Oil5W30 from "./Pages/Products/5W-30";
import Oil10W30 from "./Pages/Products/10W-30";
import Sae140 from "./Pages/Products/Sae-140";

function App() {
  return (
    <div className="App">

    {/* ROUTEs */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/aboutus" element={<AboutUs  />} />
        <Route path="/product" element={<ProductPage />} />
        <Route path="/contactus" element={<ContactUs />} />

        {/* PRODUCTS ROUTES */}
        {/* Gasoline */}
        <Route path="/0w20" element={<Oil0W20 />} />
        <Route path="/5w20" element={<Oil5W20 />} />
        <Route path="/5w30" element={<Oil5W30 />} />

        {/* Diesel/gasoline */}

        <Route path="/10w30" element={<Oil10W30 />} />
        <Route path="/10w40" element={<Oil10W40 />} />
        <Route path="/15w40" element={<Oil15W40 />} />
        <Route path="/20w50" element={<Oil20W50 />} />

        {/* Diesel */}

        <Route path="/hd50" element={<OilHD50 />} />

        <Route path="/enginflush" element={<EnginFlush />} />
        <Route path="/cvtoil" element={<CvtOil />} />
        <Route path="/dexron" element={<Dexron />} />
        <Route path="/sae90" element={<Sae90 />} />
        <Route path="/sae140" element={<Sae140 />} />

      </Routes>
    </BrowserRouter>

    </div>
  );
}

export default App;
