import React from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import Image from '../../Components/Image/Image'
import "../../Styles/AboutUs.css"
import Footer from '../../Components/Footer/Footer'

import PageDetail from '../../Components/PageDetail/PageDetail'

export default function AboutUs() {
  return (
    <div className='AboutUs'>
        <div className="AboutUsNAv d-flex justify-content-center">
            <Navbar /> 
        </div>
        <div className="AboutUsBnner d-flex justify-content-center">
            <Image Src="/images/aboutusBanner.webp" />
        </div> 

        {/************************* 
         * SECTION 2
        Wide Range Cetagories Section
       * ***************************** */}
        <div className="AboutUS">
          <PageDetail Color="black"  text="Categories" text1=" WIDE RANGE OF LUBRICANT" text2="SOLUTIONS" text3 ="Company dedicated to providing the highest quality products for all your engine needs. Our advanced formulation is specially designed to deliver maximum protection against wear and tear, resulting in a smoother, more efficient engine performance. We pride ourselves on using only the finest ingredients and adhering to strict manufacturing standards to ensure that every bottle of PUREOIL meets our uncompromising quality standards. " />
        </div>

        {/************************* 
         * SECTION 3
        Missin Section
       * ***************************** */}
        <div className="Mission" id="mission">
          <PageDetail  text="Our Mission" text1=" PROVIDE WITH PUREST " text2="LUBRICANT?" text3 ="At PUREOIL, our mission is to provide our customers with the purest and most reliable lubricant products on the market. We are committed to excellence and strive to deliver the highest level of customer satisfaction through our top-quality products and exceptional customer service. We believe in building long-term relationships with our customers, and we aim to exceed their expectations every step of the way." />
        </div>

        {/************************* 
         * SECTION 4
        Goal Section
       * ***************************** */}
        <div className="ourGoal" id="goal">
          <PageDetail Color="black"  text="Our Goal" text1=" EXTEND THE LIFESPAN  <br> OF YOUR" text2="ENGINE" text3 ="Our goal is to help extend the lifespan of your engine while reducing maintenance costs. We want you to enjoy a worry-free driving experience with the knowledge that your engine is protected from harmful contaminants, corrosion, and oxidation. We are dedicated to continuous improvement and innovation, always striving to develop new and better lubricant solutions that meet the ever-evolving needs of our customers. At PUREOIL, we are passionate about providing you with the best-in-class lubricant for all your engine needs." />
        </div>

        {/************************* 
         * SECTION 4
        FOOTER
       * ***************************** */}

        <Footer Src1="/images/gmail.svg" Src2="/images/facebook.svg" Src3="/images/instagram.svg" />    

    </div>
  )
}
