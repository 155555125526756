import React from 'react'
import Text from '../Text/Text'
import "../VerticalBorderText/VerticalBorderText.css"

export default function VerticalBorderText({text, text1, Color1, Color2}) {
  return (
    <div className='verticalBorderText'>
        <p className='verticalBorder'></p>
          <Text text={text}  Color={Color1} FontWeight="600"/>
          <Text text={text1} Color={Color2} />
    </div>
  )
}
